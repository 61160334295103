import { Button, Typography, useTheme, Box, 
    FormControl, FormControlLabel,
    InputLabel, TextField, MenuItem, Select,
    Radio, RadioGroup, Checkbox, OutlinedInput, ListItemText} from "@mui/material";
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { styled } from '@mui/system'
import MetaTags from "../components/MetaTags";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';

export default function Office() {

const theme = useTheme()
const [ second, setSecond ] = useState(false)
const [ third, setThird ] = useState(false)



const [form, setForm] = useState({
   firstName: "",
   lastName: "",
   gender: "",
   phoneNumber: "",
   email: "",
   address: "",
   postalCode: "",
   distanceFromWork: "",
   modeOfTransport: "",
   status: "",
   reference: "",
   appliedPosition: "",
   locationName: "",
   otherAppliedLocation: "",
   employmentDuration: "",
   workDays: "",
   workHours: "",
   currentEmployed: "",
   previousEmployed: "",
   workPermit: "",
   vacation: "",
   excelLevel: "",
   criminalRecord: "",
   referenceCheck: "",
   skill: "",
   school: "",
   degree: "",
   graduationYear: "",
   company: "",
   companyPosition: "",
   wage: "",
   leaveReason: "",
   startDate: "",
   endDate: "",
   company2: "",
   companyPosition2: "",
   wage2: "",
   leaveReason2: "",
   startDate2: "",
   endDate2: "",
   company3: "",
   companyPosition3: "",
   wage3: "",
   leaveReason3: "",
   startDate3: "",
   endDate3: "",
   monTime: null,
   tuesTime: null,
   wedTime: null,
   thurTime: null,
   friTime: null,
   satTime: null,
   sunTime: null,
})

const handleChange = (e) => {
   setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
}


// Set page number
const [ page, setPage ] = useState(1)

const handleNextPage = (e) => {
   e.preventDefault()
   console.log(e.target)
   if ((pages.length) > page){
       setPage(page+1)
   }

   if (page === 6) {
       sendEmail(e)
   }
   document.documentElement.scrollTop = 50
}

const handlePreviousPage = () => {

   if (page > 0) { 
       setPage(page-1)
   }
   document.documentElement.scrollTop = 15
}

const forms = useRef();    

const sendEmail = (e) => {
   e.preventDefault();
   console.log(forms.current)
   emailjs.sendForm('service_6jy75ge', 'template_kfll8mm', forms.current, 'JxdUi7ueAgPAqoMYR')
     .then((result) => {
        console.log(result.text);
        // Pop up alert and bring user to the home page
        window.location.href = '/';
        alert("Your application has been submitted!")
     }, (error) => {
         console.log(error.text);
     });
 };

 const skills = [
    'None',
    'Microsoft Office',
    'Microsoft Teams',
    'Quickbooks',
    'Sage 50',
    'Others',
 ]

 const [skillName, setSkill] = useState([]);

 const handleSkillChange = (event) => {
    const {
      target: { value },
    } = event;
    setSkill(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(', ') : value,
    );
  };

 const EmployButton = styled(Button)(() => ({
   fontFamily: 'Ubuntu',
   fontSize: '1rem',
   fontWeight: 600,
   lineHeight: '1.92rem',
   marginBottom: '20px',
   letterSpacing: '0px',
   maxWidth: '95%',
   maxHeight: '100px',
   borderRadius: '10px',
   backgroundColor: theme.palette.primary.main,
   color: 'white',
   opacity: 0.90,
   border: 'solid 1px orange',
   '&:hover': {
       backgroundColor: 'rgba(0,0,0,0)',
       color: 'black',
       border: 'solid 1px black'
   },
   '&:focus': {
       backgroundColor: 'rgba(100,100,100,0.15)',
       color: 'white',
       border: 'solid 1px black'
   }
}))

// Contract Info Page
const page1 = (
   <Box
       sx={{
           justifyItems: "center",
           alignItems: "center",
           display: "flex",
           flexDirection: "column",
           width:'100%',
       }}
   >
       <FormControl
           sx={{
               width: "95%",
               }}>

           {/* Name Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px",
               }}>
               Name
           </Typography>
           <Box
               sx={{
                   display:'flex',
                   flexDirection:'row',
               }}>
               <TextField 
                   required
                   variant="outlined" 
                   label="First Name"
                   onChange={handleChange}
                   name="firstName"
                   value={form.firstName}
                   sx={{
                       margin: "10px",
                       width: "40%",
                       maxWidth: "400px",
                   }}>
               </TextField>
               <TextField 
                   required
                   variant="outlined" 
                   label="Last Name"
                   onChange={handleChange}
                   value={form.lastName}
                   name="lastName"
                   sx={{
                       margin: "10px",
                       color: theme.palette.primary.light,
                       width: "40%",   
                       maxWidth: "400px",
               }}></TextField>
           </Box>

           {/* Gender Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Gender
           </Typography>
           <RadioGroup
               required
               onChange={handleChange}
               value={form.gender}
               name="gender"
               sx={{
                   ml:"15px",
               }}>
               <FormControlLabel value="Male" control={<Radio size="small"/>} label="Male" />
               <FormControlLabel value="Female" control={<Radio size="small"/>} label="Female" />
               <FormControlLabel value="Others" control={<Radio size="small"/>} label="Others" />
           </RadioGroup>

           {/* Phone Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px",
               }}>
               Phone Number
           </Typography>
           <TextField 
                   required
                   placeholder="123-456-7890"
                   inputProps={{
                       maxLength: 12, // adjust as needed based on your formatting
                       pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}", // only allow numeric input
                     }}
                   variant="outlined" 
                   label="eg. 123-456-7890"
                   helperText="Please include area code"
                   onChange={handleChange}
                   value={form.phoneNumber}
                   name="phoneNumber"
                   sx={{
                       margin: "10px",
                       color: theme.palette.primary.light,
                       maxWidth: "300px",
               }}></TextField>

           {/* Email Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px",
               }}>
               Email
           </Typography>
           <TextField 
                   required
                   variant="outlined" 
                   label="Email"
                   type={"email"}
                   onChange={handleChange}
                   value={form.email}
                   name="email"
                   sx={{
                       margin: "10px",
                       color: theme.palette.primary.light,
                       maxWidth: "500px",
               }}></TextField>

           {/* Address Section*/}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px",
               }}>
               Location
           </Typography>
           <Box>
               <TextField 
                   required
                   multiline
                   variant="outlined" 
                   label="Address"
                   onChange={handleChange}
                   value={form.address}
                   name="address"
                   sx={{
                       margin: "10px",
                       color: theme.palette.primary.light,
                       width: "55%",
                       maxWidth: "500px",
                   }}>
               </TextField>
               <TextField 
                   required
                   variant="outlined" 
                   label="Postal Code"
                   onChange={handleChange}
                   value={form.postalCode}
                   name="postalCode"
                   sx={{
                       margin: "10px",
                       color: theme.palette.primary.light,
                       width: "33%",
                       maxWidth: "200px",
               }}></TextField>
           </Box>

           {/* Status Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px",
               }}>
               Status
           </Typography>
           <FormControl size="large" sx={{ m: 1, minWidth: 120, maxWidth:"450px" ,width: "80%",}}>
               <InputLabel id="status">Status</InputLabel>
               <Select
                   required
                   labelId="status"
                   label="status"
                   onChange={handleChange}
                   value={form.status}
                   name="status"
                   sx={{
                       height: "50px",
                       '& .MuiSelect-select': {
                           paddingTop: '15px',
                           paddingBottom: '15px',
                       },
                   }}>
               <MenuItem value=""><em>None</em></MenuItem>
               <MenuItem value="Citizen">Citizen</MenuItem>
               <MenuItem value="Permanent Resident">Permanent Resident</MenuItem>
               <MenuItem value="Work Permit"> Work Permit</MenuItem>
               <MenuItem value="Study Permit">Study Permit</MenuItem>
               <MenuItem value="Overseas Application">Overseas Application</MenuItem>
               <MenuItem value="Other">Others</MenuItem>
               </Select>
           </FormControl>

           {/* Work Permit Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Are you legally entitled to work in Canada
           </Typography>
           <RadioGroup
               required
               onChange={handleChange}
               value={form.workPermit}
               name="workPermit"
               sx={{
                   ml:"15px",
               }}>
               <FormControlLabel value="Yes" control={<Radio size="small"/>} label="Yes" />
               <FormControlLabel value="No" control={<Radio size="small"/>} label="No" />
               <FormControlLabel value="Other" control={<Radio size="small"/>} label="Others (Select if applying from overseas)" />
           </RadioGroup>
       </FormControl>
   </Box>
)

const page2 = (
   <Box
        sx={{
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width:'100%',
        }}
    >
       <FormControl
           sx={{
               width: "95%",
               }}
       >

           {/* Referred By Section*/}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Are you referred by anyone that currently works at CoCo
           </Typography>
           <TextField
               label="Name of Reference"
               variant="outlined"
               onChange={handleChange}
               value={form.reference}
               name="reference"
               helperText="Enter None if no referrence"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "300px",
               }}
           />

           {/* Applying Position Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Position Applying To
           </Typography>
           <FormControl size="large" sx={{ m: 1, minWidth: 120, maxWidth:"450px" ,width: "80%",}}>
               <InputLabel id="position">Position</InputLabel>
               <Select
                   required
                   labelId="position"
                   label="position"
                   onChange={handleChange}
                   value={form.appliedPosition}
                   name="appliedPosition"
                   sx={{
                       height: "50px",
                       '& .MuiSelect-select': {
                           paddingTop: '15px',
                           paddingBottom: '15px',
                       },
                   }}>
               <MenuItem value="Office Administrator">Office Administrator</MenuItem>
               </Select>
           </FormControl> 

           {/* Duration Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Intended Duration of Employment
           </Typography>
           <RadioGroup
               required
               onChange={handleChange}
               value={form.employmentDuration}
               name="employmentDuration"
               sx={{
                   ml:"15px",
               }}>
               <FormControlLabel value="1 year or Less" control={<Radio size="small"/>} label="1 Year or Less" />
               <FormControlLabel value="1 - 2 Year" control={<Radio size="small"/>} label="1 - 2 Year" />
               <FormControlLabel value="2 Year or Above" control={<Radio size="small"/>} label="2 Year or Above" />
           </RadioGroup>
       </FormControl>
   </Box>
)

const page3 = (
   <Box
        sx={{
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width:'100%',
        }}
    >
       <FormControl
           sx={{
               width: "95%",
               }}>

                {/* Mode of Transportation Section*/}
                <Typography variant="h6"
                    sx={{
                        mt: "10px",
                        ml: "15px",
                    }}>
                    Mode of Transportation
                </Typography>
                <FormControl size="large" sx={{ m: 1, minWidth: 120, maxWidth:"450px" ,width: "80%",}}>
                    <InputLabel id="mode-of-transportion">Method</InputLabel>
                    <Select
                        required
                        labelId="mode-of-transportion"
                        label="Method"
                        value={form.modeOfTransport}
                        onChange={handleChange}
                        name="modeOfTransport"
                        sx={{
                            height: "50px",
                            '& .MuiSelect-select': {
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            },
                        }}>
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Walking">Walking</MenuItem>
                    <MenuItem value="Public Transportation">Public Transportation</MenuItem>
                    <MenuItem value="Self Drive">Self Drive</MenuItem>
                    <MenuItem value="Pick Up/Drop Off by Others">Pick Up/Drop Off by Others</MenuItem>
                    </Select>
                </FormControl>

            {/* Skills Section */}
           <Box>
                <FormControl sx={{ m: 1, minWidth: 120, maxWidth:"450px" ,width: "80%",}}>
                    <InputLabel id="Skill-select-label">Skills</InputLabel>
                    <Select
                    required
                    labelId="skill-select-label"
                    name="skill"
                    multiple
                    value={skillName}
                    onChange={handleSkillChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    sx={{
                        height: "50px",
                        '& .MuiSelect-select': {
                            paddingTop: '15px',
                            paddingBottom: '15px',
                        },
                    }}>
                        {skills.map((skill) => (
                            <MenuItem key={skill} value={skill}>
                                <Checkbox checked={skillName.indexOf(skill) > -1} />
                                <ListItemText primary={skill} />
                            </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Box>

            
            {/* Microsoft Excel Section*/}
            <Typography variant="h6"
                    sx={{
                        mt: "10px",
                        ml: "15px",
                    }}>
                    What level of experience do you have with Microsoft Excel?
                </Typography>
                <FormControl size="large" sx={{ m: 1, minWidth: 120, maxWidth:"450px" ,width: "80%",}}>
                    <InputLabel id="experience-level">Experience Level</InputLabel>
                    <Select
                        required
                        labelId="experience-level"
                        label="Experience"
                        value={form.excelLevel}
                        onChange={handleChange}
                        name="excelLevel"
                        sx={{
                            height: "50px",
                            '& .MuiSelect-select': {
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            },
                        }}>
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advanced</MenuItem>
                    <MenuItem value="Expert">Expert</MenuItem>
                    </Select>
                </FormControl>

        

            {/* Criminal Record Section */}
            <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Are you able to provide a criminal record check?
           </Typography>
           <RadioGroup
               required
               onChange={handleChange}
               value={form.criminalRecord}
               name="criminalRecord"
               sx={{
                   ml:"15px",
               }}>
               <FormControlLabel value="Yes" control={<Radio size="small"/>} label="Yes" />
               <FormControlLabel value="No" control={<Radio size="small"/>} label="No" />
           </RadioGroup>

           {/* Refernece Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Are you able to provide a reference check?
           </Typography>
           <RadioGroup
               required
               onChange={handleChange}
               value={form.referenceCheck}
               name="referenceCheck"
               sx={{
                   ml:"15px",
               }}>
               <FormControlLabel value="Yes" control={<Radio size="small"/>} label="Yes" />
               <FormControlLabel value="No" control={<Radio size="small"/>} label="No" />
           </RadioGroup>


           {/* Education Section */}
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Highest Level of Education
           </Typography>
           <TextField
               label="School"
               variant="outlined"
               required
               onChange={handleChange}
               value={form.school}
               name="school"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "600px",
               }}
           />
           <TextField
               label="Degree"
               onChange={handleChange}
               value={form.degree}
               name="degree"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               required
               label="Graduation Year"
               value={form.graduationYear}
               name="graduationYear"
               onChange={handleChange}
               helperText="Expected year if not yet graduated"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           />
       </FormControl>
   </Box>
)


const page4 = (
   <Box
        sx={{
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width:'100%',
        }}
    >
       <FormControl
           sx={{
               width: "95%",
               }}>

           {/* Employment Background 1 section */}
           <Typography variant="h5"
               sx={{
                   mt: "15px",
                   ml: "15px", 
                   mb: "15px",
               }}>
               Employment Background
           </Typography>
           <Typography variant="h6"
               sx={{
                   ml: "15px", 
                   mb: "15px",
               }}>
               (Leave blank if no previous work experience)
           </Typography>
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Company Info 1
           </Typography>
           <TextField
               label="Company Name"
               onChange={handleChange}
               value={form.company}
               name="company"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               label="Position at Company"
               onChange={handleChange}
               value={form.companyPosition}
               name="companyPosition"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               label="Salary/Wage"
               onChange={handleChange}
               value={form.wage}
               name="wage"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "150px",
               }}
           />
           <TextField
               label="Reason of Leave"
               onChange={handleChange}
               value={form.leaveReason}
               name="leaveReason"
               multiline
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "550px",
               }}
           />
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Employment Date 1
           </Typography>
           <TextField
               label="From (MM/YYYY)"
               value={form.startDate}
               name="startDate"
               onChange={handleChange}
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           />
           <TextField
               label="To (MM/YYYY)"
               value={form.endDate}
               name="endDate"
               onChange={handleChange}
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           />

           
           {second ? (<>
           <Typography variant="h6"
               sx={{
                   mt: "125px",
                   ml: "15px", 
               }}>
               Company Info 2
           </Typography>
           <TextField
               label="Company Name"
               onChange={handleChange}
               value={form.company2}
               name="company2"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               label="Position at Company"
               onChange={handleChange}
               value={form.companyPosition2}
               name="companyPosition2"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               label="Salary/Wage"
               onChange={handleChange}
               value={form.wage2}
               name="wage2"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "150px",
               }}
           />
           <TextField
               label="Reason of Leave"
               onChange={handleChange}
               value={form.leaveReason2}
               name="leaveReason2"
               multiline
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "550px",
               }}
           />
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Employment Date 2
           </Typography>
           <TextField
               label="From (MM/YYYY)"
               value={form.startDate2}
               name="startDate2"
               onChange={handleChange}
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           />
           <TextField
               label="To (MM/YYYY)"
               value={form.endDate2}
               name="endDate2"
               onChange={handleChange}
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           /></>) : 
           <Box sx={{ margin:'10px', marginTop:'40px'}}>
               <EmployButton  onClick={() => setSecond(true)} >
               + Employment Background
               </EmployButton>
           </Box>
           }

           {third ? (<>
           <Typography variant="h6"
               sx={{
                   mt: "125px",
                   ml: "15px", 
               }}>
               Company Info 3
           </Typography>
           <TextField
               label="Company Name"
               onChange={handleChange}
               value={form.company3}
               name="company3"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               label="Position at Company"
               onChange={handleChange}
               value={form.companyPosition3}
               name="companyPosition3"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "400px",
               }}
           />
           <TextField
               label="Salary/Wage"
               onChange={handleChange}
               value={form.wage3}
               name="wage3"
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "150px",
               }}
           />
           <TextField
               label="Reason of Leave"
               onChange={handleChange}
               value={form.leaveReason3}
               name="leaveReason3"
               multiline
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",   
                   maxWidth: "550px",
               }}
           />
           <Typography variant="h6"
               sx={{
                   mt: "15px",
                   ml: "15px", 
               }}>
               Employment Date 3
           </Typography>
           <TextField
               label="From (MM/YYYY)"
               value={form.startDate3}
               name="startDate3"
               onChange={handleChange}
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           />
           <TextField
               label="To (MM/YYYY)"
               value={form.endDate3}
               name="endDate3"
               onChange={handleChange}
               sx={{
                   margin: "10px",
                   color: theme.palette.primary.light,
                   width: "90%",
                   maxWidth: "200px",
               }}
           /></>) : 
           
           <Box sx={{ margin:'10px',}}>
               <EmployButton  onClick={() => setThird(true)} >
               + Employment Background
               </EmployButton>
           </Box>}
           
       </FormControl>
   </Box>
)

const page5 = (
    <Box
    sx={{
        justifyItems: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width:'100%',
    }}>
        <FormControl
            sx={{
                width: "95%",
                }}>
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                    mb: "15px",
                }}>
                Weekly Availability
            </Typography>


            {/* Monday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Monday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Monday Availability"
                value={form.monTime}
                name="monTime"
                onChange={(time) => handleChange({ target: { name: "monTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>

            {/* Tuesday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Tuesday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Tuesday Availability"
                value={form.tuesTime}
                name="tuesTime"
                onChange={(time) => handleChange({ target: { name: "tuesTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>

            {/* Wednesday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Wednesday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Wednesday Availability"
                value={form.wedTime}
                name="wedTime"
                onChange={(time) => handleChange({ target: { name: "wedTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>

            {/* Thursday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Thursday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Thursday Availability"
                value={form.thurTime}
                name="thurTime"
                onChange={(time) => handleChange({ target: { name: "thurTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>

            {/* Friday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Friday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Friday Availability"
                value={form.friTime}
                name="friTime"
                onChange={(time) => handleChange({ target: { name: "friTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>

            {/* Saturday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Saturday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Saturday Availability"
                value={form.satTime}
                name="satTime"
                onChange={(time) => handleChange({ target: { name: "satTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>

            {/* Sunday */}
            <Typography variant="h6"
                sx={{
                    mt: "15px",
                    ml: "15px", 
                }}>
                Sunday
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputTimeRangeField
                required
                label="Sunday Availability"
                value={form.sunTime}
                name="sunTime"
                onChange={(time) => handleChange({ target: { name: "sunTime", value: time } })}
                sx={{
                    margin: "10px",
                    color: theme.palette.primary.light,
                    width: "90%",
                    maxWidth: "400px",
                }}
                />
            </LocalizationProvider>
        </FormControl>
    </Box>
)

const pageFinal = (
   <Box>
       <Typography variant="h5" 
               sx={{
                   textAlign: "center",
                   margin: "30px",
                   textDecoration: "underline",
               }}>
               Please Double Check All Information Filled Out And Click Submit at the Bottom of the Page
           </Typography>
       <form ref={forms} onSubmit={sendEmail}>
           {page1}
           {page2}
           {page3}
           {page4}
           {page5}
       </form>
   </Box>
)

const pages = [page1, page2, page3, page4, page5, pageFinal]


return (
   <Box>
       <MetaTags title="CoCo Edmonton Apply" description="Apply to work at CoCo Edmonton" url={window.location.href}/>

       <form id='form' onSubmit={handleNextPage}>
           {page !== 1 ? null :page1}
           {page !== 2 ? null :page2}
           {page !== 3 ? null :page3}
           {page !== 4 ? null :page4}
           {page !== 5 ? null :page5}
           {page !== 6 ? null :pageFinal}
       <Box id='box' display={"flex"} flexDirection={"row"} justifyContent={"space-between"} sx={{margin:"15px"}}>
           {page === 1 ? null : <Button variant="contained" onClick={handlePreviousPage} sx={{margin:"15px"}}>
               Prev Page
           </Button>}
           <Button variant="contained"  type="submit" sx={{margin:"15px"}}>
               {page === 6 ? "submit" : "Next Page"}
           </Button>
       </Box>
       </form>
   </Box>
   
)

}