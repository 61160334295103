import { 
    Typography,
    Card,
    CardContent,
    Box,
    Link,
    Grid,
    Button,
} from '@mui/material'
import OrderCard from '../components/OrderCard.js'
import LocationCard from '../components/LocationCard.js'
import '../config/LocationInfo.js'
import useResponsiveHelper from '../hooks/useResponsiveHelper';
import { useEffect, useState } from 'react'
import { orderByDistance } from 'geolib'
import { Helmet } from 'react-helmet'
import MetaTags from '../components/MetaTags.js';
export default function Order() {

    const { LOCATIONS } = require('../config/LocationInfo.js')
    const { isMobile, isDesktop, isTablet, isMobileOrTablet, isTabletOrDesktop } = useResponsiveHelper()
    const [ userLocation, setUserLocation ] = useState({latitude:'', longitude:''})
    const [ order, setOrder ] = useState(LOCATIONS)
    const [ store, setStore ] = useState(null)

    if (!userLocation.latitude) { navigator.geolocation.getCurrentPosition(success, error) }

    function error() {
        console.log("Unable to retrieve your location");
      }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setUserLocation({ latitude: latitude, longitude: longitude });
    }

    useEffect(() => {
        
        if (userLocation.latitude) {
            const ordered = orderByDistance(userLocation, LOCATIONS)
            setOrder(ordered)
        }
    }, [userLocation])

    const selected = (
        <OrderCard key={store?.name} location={store} user={userLocation} selected={true} />

    )

    const desktop = (
        <Box sx={{ maxWidth:'1750px', width: isTablet ? '80vw' : '100vw', display: 'flex', justifyContent:'center', pb:'30px'}}  >

            <Grid container columnSpacing={1} spacing={2} justifyContent={"center"} direction={"row"} alignItem={"center"} >
                {order.map((card) => {
                    return (
                    <Grid item sx={{ margin: isMobile ? '7px' : "10px", mb: isMobile ? '-30px' : null}} xs={isDesktop ? '5' : "12"} >
                        <OrderCard key={card.name} location={card} user={userLocation} onClick={() => {setStore(card); document.documentElement.scrollTop = 0}}></OrderCard>
                    </Grid>
                )
                })}
            </Grid>
        </Box>
    )
    return (
        <Box sx={{display:'flex', justifyContent:'center'}}>
        <MetaTags title="CoCo Edmonton Ordering" description="List of stores to order from" url={window.location.href}/>
            
            {/* ORDER CODE */}
            
            {/* <span class="glf-button" data-glf-cuid="b1eaf06e-63bf-45bc-a76d-e92c114776dc" data-glf-ruid="de9f2632-5604-4dbf-9507-f61bf4c80671" > 
                Sherwood Park
            </span>
            <Helmet>
                <script type="text/javascript" src="https://www.fbgcdn.com/embedder/js/ewm2.js" defer async />
            </Helmet>  */}
           
            {store ? selected : desktop}
        </Box>
    )

}