import {
    Box, 
    Typography, 
    useTheme,
    Card,
} from '@mui/material'
import whitebg from '../images/about/whitebg.jpg'
import useResponsiveHelper from '../hooks/useResponsiveHelper'
import MetaTags from '../components/MetaTags'

export default function About() {

    const theme = useTheme()
    const { isMobile } = useResponsiveHelper()
    const mobile = (
        <Box sx={{ 
            position: 'relative',
            width:'100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            }}>
            <img src={whitebg} alt='old' style={{ width: '90%', borderRadius: '60px', margin:'20px'}}></img>
        
        <Typography variant={isMobile ? 'h6' : 'h4'} sx={{ pb:'20px', position:'absolute', top:'80px', right:'60px', left:'130px' }}>
        The first CoCo Store opened in 1997 in Taipei.
        </Typography>
        <Box sx={{ backgroundColor: theme.palette.primary.main, width:'100vw' }}>
        <Typography variant={isMobile ? 'body1' : 'h6'} sx={{ textAlign:'center', p:'10px'}}>
                        Premium bubble tea brand
                        with 8 locations in Edmonton,
                        and 5000+ stores worldwide!
                    </Typography>
        </Box>
       
        </Box>
    )

    const desktop = (
        <Box>
            <Box sx={{ width:'60%', display:'flex', }} >
                    <Typography variant={isMobile ? 'h6' : 'h4'} sx={{ pb:'20px' }}>
                    The first CoCo Store opened in 1997 in Taipei.
                    </Typography>
                    <Typography variant={isMobile ? 'body1' : 'h6'} sx={{ }}>
                        Premium bubble tea brand
                        with 8 locations in Edmonton,
                        and 5000+ stores worldwide!
                    </Typography>
                </Box>
                <Box
                    component='img'
                    alt='logo'
                    src={whitebg}
                    sx={{ 
                        position: 'relative',
                        width: isMobile ? '70%' : '40%',
                        borderRadius: '80px',
                        }}
                    />
        </Box>
    )
    return (
        
        <Box sx={{ display:'flex', flexDirection:'column', justifiyContent:'center', alignItems:'center', pb:'50px'}} >
            <MetaTags title="CoCo Edmonton About" description="Information about CoCo" url={window.location.href}/>

            <Box sx={{ pb:'30px', width: isMobile ? '100%' : '50%', display:'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent:'space-between', alignItems:'center', }} >
                {isMobile ? <Box
                    component='img'
                    alt='logo'
                    src={whitebg}
                    sx={{ 
                        width: '80%',
                        borderRadius: '60px',
                        mb:'20px'
                        }}
                /> : null}
                <Box sx={{ width:'80%', display:'flex', flexDirection:'column', }} >
                    <Typography variant={isMobile ? 'h6' : 'h4'} sx={{ textAlign:'center', pb:'20px' }}>
                    The first CoCo Store opened in 1997 in Taipei.
                    </Typography>
                    <Typography variant={isMobile ? 'body1' : 'h6'} sx={{ textAlign:'center'}}>
                        Premium bubble tea brand
                        with 8 locations in Edmonton,
                        and 5000+ stores worldwide!
                    </Typography>
                </Box>
                {isMobile ? null : <Box
                    component='img'
                    alt='logo'
                    src={whitebg}
                    sx={{ 
                        width: '40%',
                        borderRadius: '80px',
                        }}
                    />}
                    
            </Box>
            <Box sx={{ backgroundColor: isMobile ? theme.palette.primary.main : null, display:'flex', justifiyContent:'center', alignItems:'center', flexDirection: 'column'}}>
                <Typography variant={isMobile ? 'body2' : 'body1'} sx={{backgroundColor: theme.palette.background.default, m:'15px',maxWidth: isMobile ? '80%' : '60%', p:'20px', border:'1px black dashed'}}>
                    CoCo Fresh Tea & Juice is founded in 1997 by our Chairman Tommy Hung. Through 22 years of development, CoCo has become the global leading brand in the bubble tea industry.<br/><br/>
                    Through high standards of quality, consistency, and service, we have established more than 5000+ stores around the world. We provide creative tea drinks such as fresh tea, milk tea, fruit tea, yogurt drinks, coffee and other beverage products to drink lovers around the world.<br/><br/>
                    As our franchisees seek to provide drinks that give people sparks of happiness and warmth, CoCo Fresh Tea & Juice supports  partners and franchisees worldwide with our professional training programs, well-designed evaluation system, innovative products, and the assistance of our highly experienced management team.<br/><br/>
                    We continuously dedicate ourselves in innovating and progressing to build a sustainable and profitable business system. We never cease to develop new product lines, upgrade the customer experience, and broaden the accessibility to our brand, thus penetrating into the worldwide markets, and becoming the global leading brand.
                    <br/><br/>We look forward to seeing you enjoy our drinks.
                </Typography>
            </Box>
            
            
        </Box>
        
    )
}