import { 
    Box,
    Typography, 
    Link, 
    Grid, 
    Divider,
    IconButton,
    } from '@mui/material'
import './LocationCard'
import { Instagram, Facebook } from '@mui/icons-material';
import theme from '../themes/theme';
import logo from '../images/logos/coco-logo.png'
import useResponsiveHelper from '../hooks/useResponsiveHelper';


export default function Footer() {
    const { LOCATIONS } = require('../config/LocationInfo.js')
    const mapHref = 'https://www.google.com/maps?q='
    const { isMobile, isDesktop, isTablet, isMobileOrTablet, isTabletOrDesktop, containerMaxWidth, containerWidth } = useResponsiveHelper()

    return (
        <Box sx={{
            backgroundColor:theme.palette.primary.main,
            p:'20px'
        }}>
                <Box sx={{ 
                    display:'flex', 
                    justifyContent:'center', 
                    alignItems:'center',
                    my:'15px',
                    }}>
                    <Box  component='img'
                        alt='logo'
                        src={logo}
                        sx={{
                            borderRadius:'70px',
                            pt:'3px',
                            px:'5px',
                            pb:'5px',
                            backgroundColor:'rgba(255, 255, 255, 0.8)',
                            width: isMobile ? '50px' : '70px',
                    }}></Box>
                            
                    <Typography variant={isMobile ? 'h1' : "h1"} color={theme.palette.text.secondary} sx={{pl:'25px'}} >
                        CoCo Edmonton
                        <Typography variant={isMobile ? 'h6' : 'h5'} color={theme.palette.text.secondary} pt='10px'>
                            Fresh Tea and Juice
                        </Typography>
                    </Typography>
                </Box>
                
                <Grid container padding="20px" justifyContent='center' direction={isDesktop ? 'row' : 'column'}>
                    <Grid item xs={12} sx={{mb:'15px'}}>
                        <Divider variant='middle' flexItem sx={{borderColor: theme.palette.text.secondary, mr:'10%', ml:'10%', mb:'10px'}} />
                    </Grid>
                    <Grid item xs={2.5} >
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Typography variant={isMobile ? 'h5' : 'h4'} color={theme.palette.text.secondary} pb='5px' >
                                    Contact Us
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={isMobile ? 'body2' : 'body1' } >
                                    <Link href="/contact" color={theme.palette.text.secondary}>
                                        office@cocoedmonton.com
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                     </Grid>
                    <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} variant='middle' flexItem sx={{borderColor:theme.palette.text.secondary, mr:'40px', m:'30px'}} />

                    <Grid item xs={5} justifyContent={'center'}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant={isMobile ? 'h5' : 'h4'} color={theme.palette.text.secondary} >
                                    Our Locations
                                </Typography>
                            </Grid>
                            {LOCATIONS.map((store) => 
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <Typography variant={isMobile ? 'body1' : 'h6' } paddingBottom={'5px'} color={theme.palette.text.secondary}>
                                        {store.name}
                                    </Typography>
                                    <Typography variant={isMobile ? 'body2' : 'body1' } color={theme.palette.text.secondary}>
                                        <Link href={`${mapHref}${store.map}`} color={theme.palette.text.secondary}>
                                        {store.location}
                                        </Link>
                                    </Typography>
                                </Grid> 
                            )}                   
                        </Grid>
                    </Grid>
                    <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} variant='middle' flexItem sx={{borderColor:theme.palette.text.secondary, mr:'40px', m:'30px'}} />

                    <Grid item xs={2}>
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography variant={isMobile ? 'h5' : 'h4'} color={theme.palette.text.secondary} pb='5px'>
                                    Socials
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <IconButton href='https://www.instagram.com/cocoteaedmonton/?hl=en'>
                                    <Instagram fontSize="large" sx={{color:theme.palette.text.secondary, }}/>
                                </IconButton>
                                <IconButton href='https://www.facebook.com/cocoedmonton1/'>
                                    <Facebook fontSize="large" sx={{color:theme.palette.text.secondary, }}/>
                                </IconButton>
                            </Grid>
            
                        </Grid>
                    </Grid>
                </Grid>
        </Box>
    )
}