import applyImage from '../images/background/apply_background1.jpg'
import { Typography, Box, Button} from "@mui/material";
import useResponsiveHelper from '../hooks/useResponsiveHelper';
import MetaTags from '../components/MetaTags';


export default function ApplyStart() {

    const { isMobile } = useResponsiveHelper()

    const desktopView = (
        <Box 
            sx={{
                justifyItems: "center",
                alignItems: "right",
                display: "flex",
                flexDirection: "row",
                width:'100%',
            }}>

            {/* Text Field and Button */}
            <Box
                sx={{
                    height: "100%",
                    width: "50%",
                    justifyItems: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    margin:"15px",
                }}
            >
                <Typography variant='body1' textAlign={"center"}>
                    Join our Team at Coco Fresh Tea & Juice!
                    <br></br>
                    <br></br>
                    Are you passionate about serving refreshing beverages and creating memorable experiences
                    for customers? Coco Fresh Tea & Juice is looking for talented individuals to join our team.
                    As a leading global brand in the beverage industry, we take pride in offering high-quality,
                    delicious drinks made from the finest ingredients.
                    <br></br>
                    <br></br>
                    At Coco Fresh Tea & Juice, we believe in investing in our employees and providing 
                    opportunitiescfor growth. Joining our team means being part of a dynamic and diverse 
                    workforce that values teamwork, creativity, and excellence.
                    <br></br>
                    <br></br>
                    Kindly select one of the three positions listed below for which you wish to apply.
                </Typography>
                <Box>
                    <Button 
                        variant='contained'
                        href='/instore'
                        sx={{
                            margin: "15px",
                        }}>
                        InStore Staff (Part/Full Time)
                    </Button>
                    <Button 
                        variant='contained'
                        href='/warehouse'
                        sx={{
                            margin: "15px",
                        }}>
                        Warehouse Staff (Full Time)
                    </Button>
                    <Button 
                        variant='contained'
                        href='/office'
                        sx={{
                            margin: "15px",
                        }}>
                        Office Staff (Full Time)
                    </Button>
                </Box>
            </Box>

            {/* Apply Image */}
            <Box
                sx={{
                    height: "100%",
                    width: "50%",
                    justifyItems: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <img src={applyImage} alt="apply image" 
                    style={{
                        height: '100vh',
                        minHeight:'500px',
                        width: '38vw',
                    }}/>
            </Box>
        </Box>
    )

    const mobileView = (
        <Box
            sx={{ 
                height: '80vh',
                minHeight:'500px',
                backgroundImage: `url(${applyImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundOverflow:'hidden',
                display: 'flex',
                justifyContent:'left',
                alignContent:'left',
                zIndex:2,
            }}
        >
            <Box sx={{
                height: '100%', 
                width: '100%',
                position:'relative', 
                display: 'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems: 'center',
                zIndex:1,
                backgroundColor:'rgba(255,255,255,0.7)',
                margin: "auto",
            }}>
                {/* Text Field and Button */}
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection:'column',
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant='body1' textAlign={"center"} sx={{margin:'10px'}}>
                    Join our Team at Coco Fresh Tea & Juice!
                    <br></br>
                    <br></br>
                    The following form will take around 20 minutes to fill out, it contains 5 pages and
                    will ask for some personal information.
                    <br></br>
                    <br></br>
                    Kindly select one of the three positions listed below for which you wish to apply.
                </Typography>
                    <Button 
                        variant='contained'
                        href='/Instore'
                        sx={{
                            margin: "15px",
                        }}>
                        InStore Staff (Part/Full Time)
                    </Button>
                    <Button 
                        variant='contained'
                        href='/warehouse'
                        sx={{
                            margin: "15px",
                        }}>
                        Warehouse Staff (Full Time)
                    </Button>
                    <Button 
                        variant='contained'
                        href='/office'
                        sx={{
                            margin: "15px",
                        }}>
                        Office Staff (Full Time)
                    </Button>
            </Box>
            </Box>
        </Box>
    )

    return(
        <div>
            <MetaTags title="CoCo Edmonton Apply" description="Apply to work at CoCo Edmonton" url={window.location.href}/>
            {isMobile ? mobileView : desktopView}
        </div>
    )
}