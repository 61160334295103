import {React, useRef} from 'react';
import { Container, Typography, TextField, Button, Box, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import emailjs from '@emailjs/browser';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MetaTags from '../components/MetaTags';



export default function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault()

        // Create a FormData object and append all form fields
        const formData = new FormData(form.current);
        
        // Attachments validation
        const attachments = form.current.attachment.files;
        for (let i = 0; i < attachments.length; i++) {
            if (attachments[i].type.match(/^image\//)) {
                // If the file is an image, append it to the FormData
                formData.append(`attachments[${i}]`, attachments[i], attachments[i].name);
            } else {
                // If any file is not an image, alert the user and stop the function
                alert("Only image files are allowed to be attached.");
                return; // Stop the function
            }
        }
        emailjs.sendForm('service_6jy75ge', 'template_ilkyxgl', form.current, 'JxdUi7ueAgPAqoMYR')
        .then((result) => {
            console.log(result.text);
            window.location.href = '/'
            alert("Your email have been sent! Please wait for a response.")
        }, (error) => {
            console.log(error.text);
        });
        
    };
    

    return (
        <Container maxWidth="sm">
            <MetaTags title="CoCo Edmonton Contacts" description="Ways to contact CoCo Edmonton locations" url={window.location.href}/>

            <Typography variant="h4" gutterBottom>Contact Us</Typography>
            <form ref={form} onSubmit={sendEmail}>
                <TextField
                    id="name"
                    label="Name"
                    name="name"
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle  />
                          </InputAdornment>
                        ),
                      }}
                />

                <TextField
                    id="phone"
                    label="Phone Number"
                    name="phoneNumber"
                    helperText="eg. 123-456-7890"
                    fullWidth
                    required
                    type="tel"
                    placeholder="123-456-7890"
                    inputProps={{
                        maxLength: 12, // adjust as needed based on your formatting
                        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}", // only allow numeric input
                      }}
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

                <TextField
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

                <FormControl size="large" sx={{ margin: "10px", width: '100%'}}>
                    <InputLabel id="location">Location</InputLabel>
                    
                    <Select
                        required
                        labelId="location"
                        name="location"
                        id="location"
                        label="location"
                        sx={{
                            height: "50px",
                            '& .MuiSelect-select': {
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            },
                        }}
                        startAdornment= {
                          <InputAdornment position="start">
                            <LocationOnIcon/>
                          </InputAdornment>
                        }
                      >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Downtown">Downtown</MenuItem>
                    <MenuItem value="South - Calgary Trail">South - Calgary Trail</MenuItem>
                    <MenuItem value="West Edmonton Mall">West Edmonton Mall</MenuItem>
                    <MenuItem value="North - 97 St">North - 97 St</MenuItem>
                    <MenuItem value="Tamarack">Tamarack</MenuItem>
                    <MenuItem value="Windermere">Windermere</MenuItem>
                    <MenuItem value="University">University</MenuItem>
                    <MenuItem value="Westgate">Westgate</MenuItem>
                    <MenuItem value="Sherwood Park">Sherwood Park</MenuItem>
                    <MenuItem value="Manning Town Centre">Manning Town Centre</MenuItem>
                    </Select>
                </FormControl>
                
                <TextField
                    id="message"
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MessageIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

              <TextField
                  id="attachment"
                  label="Image"
                  name="attachment"
                  type="file"
                  fullWidth
                  InputLabelProps={{
                      shrink: true, // This is to ensure the label shrinks when the input is not focused, as file inputs behave differently from text inputs
                  }}
                  sx={{
                      margin: "10px",
                  }}
                  inputProps={{
                    accept: "image/*", // Restricts files to image types only
                }}
              />

                
                
                {/* Submit Button */}
                <Box display={"flex"} justifyContent={"center"} margin="10px">
                    <Button type="submit" variant="contained" color="primary" size="large">
                        Submit
                    </Button>
                </Box>
            </form>
      </Container>
    )
}