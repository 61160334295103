import { 
    Typography,
    Card,
    CardContent,
    Box,
    Button,
    Divider,
    useTheme,
    styled,
    Link
} from '@mui/material'
import useResponsiveHelper from '../hooks/useResponsiveHelper';
import logo from '../images/logos/coco_logo2.png'
import { useState } from 'react'
import { getDistance } from 'geolib'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Helmet } from 'react-helmet'

export default function OrderCard(props) {

    const {name, location, hours, latitude, longitude, cuid, ruid, info, map, mapImg, image} = props.location
    const userLocation = props.user
    const selected = props.selected
    const theme = useTheme()
    const { isMobile, isDesktop } = useResponsiveHelper()
    const mapHref = 'https://www.google.com/maps?q='

    const StyledButton = styled(Button)(({ bg }) => ({
        fontFamily: 'Fredoka',
        fontSize: isMobile ? '0.6rem' : '1rem',
        fontWeight: 600,
        lineHeight: '1.92rem',
        letterSpacing: '0.8px',
        maxWidth: isMobile ? '55px' : '85px',
        maxHeight: isMobile ? '25px' :'35px',
        minWidth: isMobile ? '55px' :'85px', 
        minHeight: isMobile ? '25px' :'35px',
        borderRadius: '5px',
        backgroundColor: bg ? bg : theme.palette.primary.main,
        color: 'white',
        opacity: 0.90,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
            color: bg ? bg : theme.palette.primary.main,
            border: 'solid 1px black'
        },
        '&:focus': {
            backgroundColor: 'rgba(100,100,100,0.15)',
            color: bg ? bg : theme.palette.primary.main,
            border: 'solid 1px black'
        }
    }))

    const dayjs = require('dayjs')
    const today = dayjs().format('dddd')


    const card = (
        
        <CardContent sx={{backgroundColor: theme.palette.background.paper, }}>
            <Box sx={{ display:'flex', width: '100%', flexDirection: 'row', alignItems: 'center', mt:'-15px', mb:'-15px'}}>
                <Box
                    component='img'
                    alt='storefront'
                    src={image[1]}
                    sx={{ 
                        width: isMobile ? '80px' : '150px',
                        mr: isMobile ? '7px' : '25px',
                        ml: isMobile ? '-13px': 0,
                            }}
                />
                <Box sx={{ width: '100%'}}>
                    <Box sx={{textAlign: 'left', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Box>
                            <Typography variant={isMobile ? 'body2' : "h6"} sx={{ mb: isMobile ? '-5px' : '5px' }}>{name}</Typography>
                            <Typography variant={isMobile ? 'body3' : "body1"}>{location}</Typography>
                        </Box>
                        {isDesktop ? <Divider sx={{borderColor:'black', mr:'10px', ml:'10px'}} orientation='vertical' flexItem/> : null}
                        <Box sx={{ mr: isMobile ? '-9px' : null, display:'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <Typography variant={isMobile ? 'body3' : "body1"} >Distance:</Typography>
                            <Typography variant={isMobile ? 'body3' : "body1"}>&gt; {(getDistance(userLocation, {latitude, longitude}) / 1000).toFixed(2) }km</Typography> 

                        </Box>
                    </Box>
                    <Divider sx={{ borderColor:'black', m:'10px'}}/>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', flexDirection: 'row' }}>
                        <Typography variant={isMobile ? 'body3' : "body2"} sx={{ mb:'15px'}}>{hours.filter((time) => time.includes(today))}</Typography>
                        <StyledButton sx={{ mr: isMobile ? '-10px' : null}}onClick={props.onClick}>Select</StyledButton>
                    </Box>
                </Box>
            </Box> 
        </CardContent>
    )

    const selectCard = (
        <CardContent sx={{backgroundColor: theme.palette.background.paper, width: isMobile ? '90vw' : null}}>
           
            <Box sx={{ display:'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent:'space-around'}}>
                <Box
                    component='img'
                    alt='inside store'
                    src={logo}
                    sx={{ 
                        width: isMobile ? '80px' : '150px',
                        pl:'-25px',
                        mb:'10px',
                        }}
                /> 
                <Divider sx={{borderColor:'black'}} orientation='vertical' flexItem/>
                <Box sx={{ width: isMobile ? '60%' : null, textAlign: isMobile ? 'center' : 'left', display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection: 'column'}}>
                    <Box sx={{mb:'10px'}}>
                        <Typography variant={isMobile ? "body2" : "h6"} sx={{ mb: isMobile ? '-5px' : '5px' }}>{name}</Typography>
                        <Typography variant={isMobile ? 'body3' : "body1"}>{location}</Typography>
                    </Box>
                    <Helmet>
                        <script type="text/javascript" src="https://www.fbgcdn.com/embedder/js/ewm2.js" defer async />
                    </Helmet>
                    <StyledButton onClick={props.onClick} data-glf-cuid={cuid} data-glf-ruid={ruid}>Order</StyledButton>    
                </Box>                
                {!isMobile ? <Divider sx={{borderColor:'black'}} orientation='vertical' flexItem/> : null}
                {!isMobile ? 
                    <Box sx={{width:'33%', textAlign: isMobile ? 'center' : 'left', display:'flex', alignItems:'left', flexDirection: 'column'}}>
                        <Typography variant={isMobile ? "body2" : "h6"} sx={{mt:'5px'}}>More Info</Typography>
                        <Typography variant={isMobile ? 'body3' : "body1"} sx={{pt:'10px', pb:'20px'}} >{info}</Typography>
                    </Box> : null}
            </Box> 
                    {isMobile ? <Divider sx={{m:'10px', "&::before, &::after": { borderColor: "black" }, fontSize:theme.typography.body2 }} variant='inset' textAlign='left'>More Info</Divider> :
                     <Divider sx={{m:'10px', borderColor: "black" , }} variant='inset' textAlign='left'></Divider>}

                {isMobile ? 
                    <Box sx={{textAlign: isMobile ? 'center' : 'left', display:'flex', alignItems:'left', flexDirection: 'column'}}>
                        <Typography variant={isMobile ? 'body3' : "body1"} sx={{ pb:'20px'}} >{info}</Typography>
                    </Box> : null}

                    <Box sx={{ display:'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent:'center'}}>

                        <a href={mapHref + map} target={'_blank'} rel={'noreferrer'} style={{display:'flex', justifyContent:'center'}}>
                            <Box
                                component='img'
                                alt='maps'
                                src={mapImg}
                                sx={{                                 
                                    width: '100%',
                                    // /pl:'-25px',
                                    mb:'10px',
                                    }}
                            /> 
                        </a>
                    </Box>
                        
        </CardContent>
        
    )
    return ( 
        <Box sx={{pb:'15px', maxWidth:'750px', }}>
            
            
            {selected ?  
            <Button href={'/order'} sx={{color:'black', }}>
                <KeyboardBackspaceIcon />
                <Typography sx={{pl:'5px', }}variant='body1'>View other stores</Typography>
            </Button> : null}

            <Card>{selected ? selectCard : card}</Card>
        </Box>
    )
}