export const DowntownOV = require('./storefront/DowntownOV.jpg')
export const DowntownIH = require('./storefront/DowntownIH.jpg')
export const SouthOV = require('./storefront/SouthOV.jpg')
export const SouthIH = require('./storefront/SouthIH.jpg')
export const TamarackOV = require('./storefront/TamarackOV.jpg')
export const TamarackIH = require('./storefront/TamarackIH.jpg')
export const WemOV = require('./storefront/WemOV.JPG')
export const WemIH = require('./storefront/WemIH.jpg')
export const WindermereOV = require('./storefront/WindermereOV.jpg')
export const WindermereIH = require('./storefront/WindermereIH.jpg')
export const UofAOV = require('./storefront/UofAOV.jpg')
export const UofAIH = require('./storefront/UofAIH.jpg')
export const NorthOV = require('./storefront/NorthOV.jpg')
export const NorthIH = require('./storefront/NorthIH.jpg')
export const SherwoodOV = require('./storefront/SherwoodOV.jpg')
export const SherwoodIH = require('./storefront/SherwoodIH.jpg')
export const WestgateOV = require('./storefront/WestgateOV.jpg')
export const WestgateIH = require('./storefront/WestgateIH.jpg')
export const ManningOV = require('./storefront/ManningOV.jpg')
export const HeritageIH = require('./storefront/HeritageIH.jpg')
export const HeritageOV = require('./storefront/HeritageOV.jpg')

export const DowntownMap = require('./maps/downtown.png')
export const SouthMap = require('./maps/south.png')
export const TamarackMap = require('./maps/tamarack.png')
export const WemMap = require('./maps/wem.png')
export const WindermereMap = require('./maps/windermere.png')
export const UofAMap = require('./maps/university.png')
export const NorthMap = require('./maps/north.png')
export const SherwoodMap = require('./maps/sherwood.png')
export const WestgateMap = require('./maps/westgate.png')
export const ManningMap = require('./maps/manning.png')
export const TempHeritageMap = require('./maps/tempHeritage.png')

export const drink1 = require('./drinks/drink1.jpg')
export const drink2 = require('./drinks/drink2.jpg')
export const drink3 = require('./drinks/drink3.jpg')
export const drink4 = require('./drinks/drink4.jpg')
export const drink5 = require('./drinks/drink5.jpg')
export const drink6 = require('./drinks/drink6.jpg')
export const drink7 = require('./drinks/drink7.jpg')
export const drink8 = require('./drinks/drink8.jpg')

export const menuVER = require('./drinks/CoCo20230606_EDT_Menu Renew/menuVER.jpg')
export const menuHOR = require('./drinks/CoCo20230606_EDT_Menu Renew/menuHOR.jpg')
export const locations = require('./drinks/CoCo20230606_EDT_Menu Renew/locations.jpg')
export const locationsADDRESS = require('./drinks/CoCo20230606_EDT_Menu Renew/locationsADDRESS.jpg')
export const locationsMAP = require('./drinks/CoCo20230606_EDT_Menu Renew/locationsMAP.jpg')

export const whitebg = require('./about/whitebg.jpg')

export const cateringImage = require('./catering/cateringImage.png')

export const logo2Image = require('./logos/coco_logo2.png')


