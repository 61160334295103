import { 
    Typography,
    Card,
    CardContent,
    Box,
    Link,
    Divider,
    useTheme,
} from '@mui/material'
import * as React from 'react'
import useResponsiveHelper from '../hooks/useResponsiveHelper';

export default function LocationCard(props) {

    const { name, hours, number, location, map, image } = props.location
    const mapHref = 'https://www.google.com/maps?q='
    const { isMobile } = useResponsiveHelper()
    const theme = useTheme()

    const mobileView = (
        <CardContent>
            <Box display={'flex'} flexDirection={'column'}  > 
                    <Typography variant="h4" sx= {{ marginBottom:"15px", paddingLeft:"15px" }} >
                            {name}
                    </Typography>
                    <Divider variant='fullWidth' sx={{borderColor:"black", margin:"10px"}}></Divider>
                    <Box sx={{display:"flex", flexDirection: "row", marginBottom:"20px", justifyContent:'space-between'}}>
                        <Box sx={{ padding:'10px',display:"flex", flexDirection: "column",}}>
                            <Typography variant="b1" paddingBottom="5px">
                                Location
                            </Typography>
                            <Link variant="body3" href={`${mapHref}${map}`} target="_blank" color="secondary">
                                {location}
                            </Link>

                        </Box>
                        <Box sx={{ padding:'10px 25px', display:"flex", flexDirection: "column",}}> 
                            <Typography variant="b1" paddingBottom="5px">
                                Phone
                            </Typography>
                            <Link variant="body3" href='tel:PHONE_NUM' color="secondary">
                                {number}
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", flexDirection: "row",}}>
                        <Box component='img' alt='logo' src={image[1]} sx={{width:"40%", height: "50%", marginRight:"10px"}} />    
                        <Box sx={{display:"flex", flexDirection: "column",}}>
                            {hours.map((time) => {
                                return(
                                    <Typography variant="body3" sx={{ marginBottom:"10px", textAlign:'right' }}>
                                        {time}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </Box>
            
                    
                    
                </Box>
        </CardContent>
    )

    const desktopView = (
        <React.Fragment >
            <CardContent >
            
                <Box sx={{
                    display: "flex",
                    justifyContent: "left",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                }}>
                   
                    <Box component='img' alt='storefront' src={image[1]} sx={{ maxHeight:"300px", paddingRight:'15px' }} />
                    
                <Box display={'flex'} flexDirection={'column'}   > 
                    <Typography variant="h3" sx= {{ marginBottom:"5px", paddingLeft:"15px" }} >
                            {name}
                    </Typography>
                    <Box sx={{ padding:'10px 25px', textAlign:'left' }}>
                    <Divider sx={{pb:'5px', m:'10px', "&::before, &::after": { borderColor: "black" }, fontSize:theme.typography.h6 }} variant='fullWidth' textAlign='left'>Hours</Divider>

                    {hours.map((time) => {
                        return(
                            <Typography variant="body2" sx={{ marginBottom:"10px", textAlign:'left' }}>
                                {time}
                            </Typography>
                        )
                    })}
                    </Box>
            
                    <Box sx={{ padding:'10px 25px' }}>
                    <Divider sx={{pb:'5px', m:'10px', "&::before, &::after": { borderColor: "black" }, fontSize:theme.typography.h6 }} variant='fullWidth' textAlign='left'>Address</Divider>

                        <Link variant="body2" href={`${mapHref}${map}`} target="_blank" color="secondary">
                            {location}
                        </Link>

                    </Box>
                    <Box sx={{ padding:'10px 25px' }}> 
                    <Divider sx={{pb:'5px', m:'10px', "&::before, &::after": { borderColor: "black" }, fontSize:theme.typography.h6 }} variant='fullWidth' textAlign='left'>Phone</Divider>

                        <Link variant="body2" href='tel:PHONE_NUM' color="secondary">
                            {number}
                        </Link>
                    </Box>
                </Box>
                </Box>
            </CardContent>
        </React.Fragment>
    )
    return (
        <Box >
            <Card >{isMobile ? mobileView : desktopView}</Card>
        </Box>
    )
}