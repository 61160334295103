import { Helmet } from 'react-helmet'
import { Box } from '@mui/material'
import { menuHOR, menuVER } from '../images'
import useResponsiveHelper from '../hooks/useResponsiveHelper';
import MetaTags from '../components/MetaTags';
export default function Drinks() {

    const { isMobile } = useResponsiveHelper()

    return (
        <div>
        <MetaTags title="CoCo Edmonton Menu" description="A menu of CoCo drinks" url={window.location.href}/>

        <Box
            component='img'
            alt='menu'
            src={isMobile ? menuVER : menuHOR}
            sx={{                                 
                width: '100%',
                
                }}

        />
        </div>
    )
}