import { createTheme } from '@mui/material';

const theme = createTheme({

     breakpoints: {
        values: {
            xs: 0,
            sm: 420, // Changing to include iphone max sizes (6, 7, 8 and X)
            md: 890,
            lg: 1440,
            mobile: 0,
            tablet: 640,
            // laptop: 1024,
            desktop: 1200,
        },
      },
      components: {
        MuiRadio: {
          styleOverrides: {
          root: {
            color: 'black',
          },
          colorSecondary: {
            '&$checked': {
              color: 'green',
            },
          },
        }},
        MuiFormHelperText: {
          styleOverrides: {
              root: {
                  color: "black"
              }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {                          
              '& .MuiOutlinedInput-root': {  
                  '& fieldset': {            
                      borderColor: 'black',   
                  },
                  '&:hover fieldset': {
                      borderColor: '#d99129', 
                  },
              },
              },
          }
        },
        MuiSelect: {
          styleOverrides: {
            root: {                          
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d99129',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d99129',
              },
              '.MuiSvgIcon-root ': {
                fill: "orange",
              }
              },
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {                          
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d99129',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d99129',
              },
              '.MuiSvgIcon-root ': {
                fill: "orange",
              }
              },
          }
        },
        MuiInputLabel: {
          styleOverrides: {
               root: {
                    color: "#d99129",
               },
               shrink: {
                    color: "black",
               },
          }
     },
        MuiButton: {
          defaultProps: {
            disableElevation: true
          },
          styleOverrides: {
            root: {
              textTransform: 'none'
            },
            sizeSmall: {
              padding: '6px 16px'
            },
            sizeMedium: {
              padding: '8px 20px'
            },
            sizeLarge: {
              padding: '11px 24px'
            },
            textSizeSmall: {
              padding: '7px 12px'
            },
            textSizeMedium: {
              padding: '9px 16px'
            },
            textSizeLarge: {
              padding: '12px 16px'
            }
          }
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true
          }
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              padding: '32px 24px',
              '&:last-child': {
                paddingBottom: '32px'
              }
            }
          }
        },
        MuiCardHeader: {
          defaultProps: {
            titleTypographyProps: {
              variant: 'h6'
            },
            subheaderTypographyProps: {
              variant: 'body2'
            }
          },
          styleOverrides: {
            root: {
              padding: '32px 24px'
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: {
            '*': {
              boxSizing: 'border-box',
              margin: 0,
              padding: 0
            },
            html: {
              MozOsxFontSmoothing: 'grayscale',
              WebkitFontSmoothing: 'antialiased',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100%',
              width: '100%'
            },
            body: {
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              minHeight: '100%',
              width: '100%'
            },
            '#__next': {
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              height: '100%',
              width: '100%'
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: '#E6E8F0'
            }
          }
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: '#F3F4F6',
              '.MuiTableCell-root': {
                color: '#374151'
              },
              borderBottom: 'none',
              '& .MuiTableCell-root': {
                borderBottom: 'none',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: 1,
                letterSpacing: 0.5,
                textTransform: 'uppercase'
              },
              '& .MuiTableCell-paddingCheckbox': {
                paddingTop: 4,
                paddingBottom: 4
              }
            }
          }
        }
      },
      palette: {
        neutral: {
          100: '#F3F4F6',
          200: '#E5E7EB',
          300: '#D1D5DB',
          400: '#9CA3AF',
          500: '#6B7280',
          600: '#4B5563',
          700: '#374151',
          800: '#1F2937',
          900: '#111827'
        },
        action: {
          active: '#6B7280',
          focus: 'rgba(55, 65, 81, 0.12)',
          hover: 'rgba(55, 65, 81, 0.04)',
          selected: 'rgba(55, 65, 81, 0.08)',
          disabledBackground: 'rgba(55, 65, 81, 0.12)',
          disabled: 'rgba(55, 65, 81, 0.26)'
        },
        background: {
          default: '#fcead4',
          paper: '#F9FAFC',
          img: '#f7ef9e',
          imgOpac: '#f5f5dc',
          light: 'rgba(251,240,186,1)',
          apply: 'rgb(208, 232, 236)',
        },
        divider: '#E6E8F0',
        primary: {
          main: '#fca10d',
          light: '#ffdab3',
          opacity: 'rgba(217,145,41,0.8)',
          contrastText: '#FFFFFF',
          teal: '#008080',
          blue: '#22296f',
        },
        secondary: {
          main: '#59666Eff',
          light: '#808080',
          dark: '#202020',
          contrastText: '#FFFFFF'
        },
        like: {
          main: 'red',
        },
        success: {
          main: '#14B8A6',
          light: '#43C6B7',
          dark: '#0E8074',
          contrastText: '#FFFFFF'
        },
        info: {
          main: '#2196F3',
          light: '#64B6F7',
          dark: '#0B79D0',
          contrastText: '#FFFFFF'
        },
        warning: {
          main: '#FFB020',
          light: '#FFBF4C',
          dark: '#B27B16',
          contrastText: '#FFFFFF'
        },
        error: {
          main: '#D14343',
          light: '#DA6868',
          dark: '#922E2E',
          contrastText: '#FFFFFF'
        },
        text: {
          primary: '#121828',
          secondary: '#FFFFFF',
          disabled: 'rgba(55, 65, 81, 0.48)'
        }
      },
      shape: {
        borderRadius: 8
      },
      shadows: [
        'none',
        '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
        '0px 1px 2px rgba(100, 116, 139, 0.12)',
        '0px 1px 4px rgba(100, 116, 139, 0.12)',
        '0px 1px 5px rgba(100, 116, 139, 0.12)',
        '0px 1px 6px rgba(100, 116, 139, 0.12)',
        '0px 2px 6px rgba(100, 116, 139, 0.12)',
        '0px 3px 6px rgba(100, 116, 139, 0.12)',
        '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
        '0px 5px 12px rgba(100, 116, 139, 0.12)',
        '0px 5px 14px rgba(100, 116, 139, 0.12)',
        '0px 5px 15px rgba(100, 116, 139, 0.12)',
        '0px 6px 15px rgba(100, 116, 139, 0.12)',
        '0px 7px 15px rgba(100, 116, 139, 0.12)',
        '0px 8px 15px rgba(100, 116, 139, 0.12)',
        '0px 9px 15px rgba(100, 116, 139, 0.12)',
        '0px 10px 15px rgba(100, 116, 139, 0.12)',
        '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
        '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
        '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
        '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)'
      ],
      typography: {
        fontFamily: ['Bubblegum Sans', 'Boogaloo', 'Fredoka', 'Bangers', 'Rubik', 'Ubuntu'].join(','),
        h1: {
            fontFamily: 'Boogaloo',
            fontWeight: 500,
            fontSize: '4rem',
            lineHeight: '4.5rem',
            letterSpacing: '-.5px',
        },
        h2: {
            fontFamily: 'Neue Helvetica Georgian 75 Bold',
            fontWeight: 600,
            fontSize: '3rem',
            lineHeight: '3.2rem',
            letterSpacing: '.5px',
            color: '#202020',
        },
        h3: {
            fontFamily: 'Varela Round',
            fontWeight: 500,
            fontSize: '3rem',
            lineHeight: '2.8rem',
            letterSpacing: '-.5px',
            color: '#202020',
        },
        h4: {
            fontFamily: 'Varela Round',
            fontSize: '2.3rem',
            fontWeight: 500,
            lineHeight: '2rem',
            letterSpacing: '.25px',
            color: '#202020',
        },
        h5: {
            fontFamily: 'Fredoka',
            fontSize: '1.725rem',
            lineHeight: '1.62rem',
            fontWeight: 600,
            letterSpacing: '0.5px',
            color: '#202020',
        },
        h6: {
            fontFamily: 'Fredoka',
            fontSize: '1.47rem',
            lineHeight: '1.3rem',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: '0.3px',
        },
        menu: {
          fontFamily: 'Varela Round',
          fontSize: '1.725rem',
          lineHeight: '1.62rem',
          fontWeight: 500,
          letterSpacing: '0.5px',
          color: '#202020',
        },
        overline: {
            fontFamily: 'Fredoka',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 400,
            letterSpacing: '0.12rem',
            textTransform: 'uppercase',
        },
        body1: {
            fontFamily: 'Fredoka',
            fontSize: '1.15rem',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.65rem',
            letterSpacing: '0px',
        },
        body2: {
            fontFamily: 'Fredoka',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
            fontStyle: 'normal',
            letterSpacing: '0px',
        },
        body3: {
            fontFamily: 'Fredoka',
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: '1rem',
            fontStyle: 'normal',
        },
        subtitle1: {
            fontFamily: 'Fredoka',
            fontSize: '1.125rem',
            fontWeight: 600,
            lineHeight: '1.75rem',
            letterSpacing: '0px',
            fontStyle: 'normal',
        },
        caption: {
            fontFamily: 'Fredoka',
            fontWeight: 400,
            lineHeight: '1rem',
            fontSize: '0.75rem',
            fontStyle: 'italic',
        },
    },
});

export default theme;