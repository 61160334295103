import {Box, AppBar, Typography, Link, useTheme, } from '@mui/material'
import logo from '../images/logos/coco_logo1.png'
import useResponsiveHelper from '../hooks/useResponsiveHelper'
import { styled } from '@mui/system'
import MobileMenu from '../components/MobileMenu'
import { useState, useEffect, useRef } from 'react'


export default function Header() {
    
    const { isMobile,} = useResponsiveHelper()
    const theme = useTheme()
    const isHomepage = window.location.pathname === '/'
    const page = ((window.location.pathname).replace('/', ''))
    const [windowSize, setWindowSize] = useState();

    const StyledLink = styled(Link)({
        borderRadius:"30px", 
        //backgroundColor:"rgba(254,245,206,0.7)", 
        padding: windowSize > 1900 ? '20px' : '5px', 
        marginRight:"35px",
    })

    const colors = {
        homepage: 'rgba(255, 255, 255, 0)',
        'apply-online': theme.palette.background.apply,
        default: theme.palette.background.default,
    }
    const [ bg, setBG ] = useState(null)

    useEffect(() => {
        //const page = ((window.location.pathname).replace('/', ''))
        setBG(isHomepage ? colors['homepage'] : colors[page] && (page === 'apply-online' && isMobile) ? colors[page] : colors['default'])
        

    }, [windowSize])

   

    const maxSize = 1801
    const offset = 300
    const [checkSize, setCheckSize] = useState(0)
    const [longList, setLongList] = useState([])
    const [menuList, setMenuList] = useState(['Order', 'Locations', 'Drinks', 'Apply', 'About', 'Contact', 'Catering'])

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize(window.innerWidth);
        }
        
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount
    
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      };
    
    const size = usePrevious(windowSize);
    const [finish, setFinish] = useState(true)
    useEffect(() => {
        if (windowSize > checkSize && finish) {
            setCheckSize(checkSize + offset);
            let item = menuList.shift()
            if (item) { longList.push(item) }
        } 
        
        if (windowSize && checkSize > windowSize) {
            setFinish(false)
        }
        
    })
    useEffect(() => {
        if (size < windowSize) {
            if (windowSize > 1000 && windowSize > checkSize && checkSize < maxSize) {
                setCheckSize(checkSize + offset);
                let item = menuList.shift()
                if (item) { longList.push(item) }
            } 
        } 

        if (size > windowSize) {
            if (windowSize < 1000 && windowSize < (checkSize - 200)) {
                setCheckSize(checkSize - offset)
                let item = longList.pop()
                if (item) { menuList.unshift(item) }
            } else if (windowSize < checkSize && windowSize < (checkSize - offset)) {
                setCheckSize(checkSize - offset);
                let item = longList.pop()
                if (item) { menuList.unshift(item) }
            } 
        }

        setLongList(longList.filter(function( element ) {
            return element !== undefined;
        }));
        setMenuList(menuList.filter(function( element ) {
            return element !== undefined;
        }));

        
    }, [windowSize]);

   
    
    const mobile = (
        <Box sx={{
            position: isHomepage ? 'absolute' : 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'right',
            backgroundColor: bg,
            zIndex:2,
            //'#ffdab3',
            //cfe8ef
        }}
    >
        <AppBar 
            position='static' 
            sx={{ 
                backgroundColor:bg,   
                paddingLeft:"20vw",
            }}
            elevation={0}
        >  
            <Link href="/" >
                <Box
                    component='img'
                    alt='logo'
                    src={logo}
                    sx={{ 
                        width: '70vw',
                        minWidth: '250px',
                        maxWidth:'300px',
                        //backgroundColor:'rgb(250, 250, 250, 0.7)',
                        borderRadius: '70px',
                        }}
                />
            </Link>
            
        </AppBar>
        <Box alignSelf="right" paddingRight="10vw" mt="-15px" onClick={() => isHomepage && bg !== theme.palette.background.light ? setBG(theme.palette.background.light) : isHomepage ? setBG(colors['homepage']) : null}>
            <MobileMenu bg_color={page === 'apply-online' ? theme.palette.background.apply : isHomepage ? theme.palette.background.light : theme.palette.background.default}/>
        </Box>
    </Box>
    )

    const desktop = (
        <Box>
            <Box sx={{
                backgroundColor: bg,
                position: isHomepage ? 'absolute' : 'relative',
                display:"flex",
                flexDirection:'row',
                alignItems: 'center',
                justifyContent: 'center',
                width:'100%',
                zIndex:2,
                margin:'auto'
                }}
            >
            <AppBar 
                position='relative' 
                sx={{ 
                    backgroundColor: bg, //'rgba(255, 255, 255, 0)',//theme.palette.primary.light,
                    display:"flex",
                    flexDirection:'row',
                    alignItems: 'right',
                    justifyContent: 'space-between',
                }}
                elevation={0}
            >
                <Link href="/">
                <Box
                    component='img'
                    alt='logo'
                    src={logo}
                    sx={{ 
                        ml:'80px',
                        mt: '-20px',
                        width: '300px',
                        //backgroundColor:'rgba(254,245,206,0.7)',
                        borderRadius: '70px',
                        }}
                />
                </Link>
                <Typography 
                    id='navbar'
                    variant= {windowSize > maxSize ? 'h3' : 'h4' }
                    sx={{ 
                        padding:'15px', 
                        mt:'20px', 
                        //mr:'170px'
                    }} >
                    {longList.map((item) => {
                        return (  <StyledLink href={item === "Apply" ? `/apply-online` : `/${item.toLowerCase()}`} color='secondary' underline='none'>
                        {item}       
                     </StyledLink>)
                    })}
                    
                   
                      
                    
                </Typography>

                <Box></Box>
            </AppBar>
            {windowSize < maxSize ? 
                <Box alignSelf="right" marginRight='150px' mt="-15px" onClick={() => isHomepage && bg !== theme.palette.background.light ? setBG(theme.palette.background.light) : isHomepage ? setBG(colors['homepage']) : null}>
                    <MobileMenu menuList={menuList} bg_color={(page === 'apply-online' && isMobile) ? theme.palette.background.apply : isHomepage ? theme.palette.background.light : theme.palette.background.default}/>
                </Box> : null}
        </Box>
    </Box>
    )


    return (
        <Box>
            {isMobile ? mobile : desktop}
        </Box>
    )
}