import { Helmet } from 'react-helmet'

export default function MetaTags(props) {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description}/>
            <meta property="og:title" content={props.title}/>
            <meta property="og:description" content={props.description}/>
            <meta property="og:url" content={props.url}/>
        </Helmet>
    )
}
