import { Typography, Button, Box, Grid, useTheme, Link } from '@mui/material';
import bgi from '../images/background/Banner_Picture.png'
import { useEffect, useState,  } from 'react'
import { styled } from '@mui/system'
import useResponsiveHelper from '../hooks/useResponsiveHelper';
import DrinkCard from '../components/DrinkCard';
import { Helmet } from 'react-helmet'
import MetaTags from '../components/MetaTags';

export default function Home() {

    const { isMobile, isDesktop, isTablet, isMobileOrTablet, isTabletOrDesktop, containerMaxWidth, containerWidth } = useResponsiveHelper()
    const [offset, setOffset] = useState(0)
    const token = process.env.REACT_APP_CLIENT_TOKEN
    const theme = useTheme()
    const ApplyButton = styled(Button)(() => ({
        fontFamily: 'Ubuntu',
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '1.92rem',
        marginBottom: '20px',
        letterSpacing: '0px',
        maxWidth: '200px',
        maxHeight: '60px',
        minWidth: '200px', 
        minHeight: '60px',
        borderRadius: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: 'white',
        opacity: 0.90,
        border: 'solid 1px white',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'black',
            border: 'solid 1px black'
        },
        '&:focus': {
            backgroundColor: 'rgba(100,100,100,0.15)',
            color: 'white',
            border: 'solid 1px black'
        }
    }))
    const StyledButton = styled(Button)(({ bg }) => ({
            fontFamily: 'arial',
            fontSize: '1.625rem',
            fontWeight: 900,
            lineHeight: '1.9rem',
            letterSpacing: '0px',
            maxWidth: '200px',
            maxHeight: '60px',
            minWidth: '200px', 
            minHeight: '60px',
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            opacity: 0.90,
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0)',
                color: theme.palette.primary.main,
                border: 'solid 1px black'
            },
            '&:focus': {
                backgroundColor: 'rgba(100,100,100,0.15)',
                color: theme.palette.primary.main,
                border: 'solid 1px black'
            }
    }))

    useEffect(() => {
        const onScroll = () => {
            setOffset(window.pageYOffset)
        }
        
        window.removeEventListener("scroll", onScroll)
        window.addEventListener("scroll", onScroll, { passive: true })
        
        return () => window.removeEventListener("scroll", onScroll)
    }, [offset]);
    
    function cards() {
        const cards = []
        for (let i = 1; i < 9; i++) {
            cards.push(
            <Grid item xs={isDesktop ? 3 : isMobile ? 12 : 4}>
                <DrinkCard image={i}/>
            </Grid>
            )
        }

        return cards
    }

    

    const mobileView = (
        <Box sx={{
            height: '100%', 
            width: '100%',
            position:'relative', 
            display: 'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems: 'center',
            zIndex:1,
            backgroundColor:'rgba(255,255,255,0.5)',
        }}>
            <Typography variant='h2' sx={{
                textAlign: 'center',
                padding: '20px',
                mt:'60px',
                fontSize: '40px',
                fontFamily: 'Rubik Lines',
                color: '#969696',
                }}>
                SPARK UP EVERY MOMENT
            </Typography>

            <StyledButton href="/order">
                Order Now!
            </StyledButton>
        </Box>
    )

    const desktopView = (
        <Box sx={{
            height: '100%', 
            position:'relative', 
            display: 'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems: 'center',
            pt:'40px',
            marginLeft: isTablet ? '-50px' : '50px',
            marginTop: isTablet ? '-20px' : '-30px',
            zIndex:1,
        }}>
            <Typography variant='h2' sx={{
                width:'75%',
                textAlign:'center',
                padding: '20px',
                fontFamily: 'Rubik Lines',
                }}>
                SPARK UP EVERY MOMENT
            </Typography>
            <StyledButton href="/order">
                Order Now!
            </StyledButton>
        </Box>
    )

    const order = (
        <Box
            sx={{ 
                height: '80vh',
                minHeight:'500px',
                backgroundImage: `url(${bgi})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundOverflow:'hidden',
                display: 'flex',
                justifyContent:'left',
                alignContent:'left',
                zIndex:2,
            }}
        >
            {isMobile ? mobileView : desktopView}
        </Box>
    )
    const apply = (
        <Box sx={{
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            padding:'30px'
        }}>
            <ApplyButton href='/apply-online'>
                Apply now!
            </ApplyButton>
            <Typography variant={isMobile ? 'h3' : 'h2'} sx={{
                mb:'30px',
                textAlign:'center',
                color:theme.palette.background.paper,
            }}>
                Looking to join our team?
            </Typography>
            <Typography variant={isMobile ? 'body1' : 'h6'} sx={{width: isMobile ? '80%' : '40%', color:'white', mb:'25px', textAlign:'center'}}>
                Let your passion for tea and customer delight shine! Join our bubble tea family and bring joy to every cup
            </Typography>
            
        </Box>
    )

    const drinks =(
        <Box sx={{
            backgroundColor: theme.palette.background.imgOpac ,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            padding: isMobile ? '15px' : '50px',
            pb:'80px'
        }}>
            <Typography variant={isMobile ? 'h3' : 'h2'} sx={{
                mb:'20px',
                color:theme.palette.primary.main,
                width:'90%',
                mt: '10px',
                textAlign:'center',
            }}>
                Need inspiration? Check out our favourites!
            </Typography>
            <Typography variant={isMobile ? 'body1' : 'h6'} sx={{color:theme.palette.primary.main, mb: isTabletOrDesktop ? '60px' : '25px', width: isMobile ? '90%' : '70%', textAlign:'center',}}>
                Also checkout out the abundance of drinks we offer with our <Link href='/drinks'>full menu!</Link>
            </Typography>
            <Grid container direction={'row'} rowSpacing={3} maxWidth='80%'>
                {cards().map((card) => card)}
            </Grid>
         </Box>
    )

    const insta = (
        <Box style={{backgroundColor: theme.palette.background.imgOpac}}>

            <div style={{backgroundColor: theme.palette.background.imgOpac}}className="elfsight-app-fb2d2c53-664a-41cb-8fac-ac7e474c1ac2"/>
            <Helmet style={{backgroundColor: theme.palette.background.imgOpac}}>
                <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer/>
            </Helmet>

        </Box>
    )
    return (
        <Box>
            <MetaTags title="CoCo Edmonton" description="CoCo Edmonton Home Page" url={window.location.href}/>
            {order}
            {apply}
            {drinks}
            {/* {insta} */}
        </Box>
    )

}
