import Home from './pages/Home'
import Instore from './pages/Instore'
import Warehouse from './pages/Warehouse'
import Office from './pages/Office'
import Order from './pages/Order'
import Header from './components/Header'
import Footer from './components/Footer'
import Locations from './pages/Locations'
import Drinks from './pages/Drinks'
import ApplyStart from './pages/ApplyStart'
import About from './pages/About'
import Contact from './pages/Contact'
import Catering from './pages/Catering'


import { ThemeProvider } from "@mui/material";
import Theme from "./themes/theme";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"
function App() {
  return (
    <>
      <ThemeProvider theme={Theme}>
        <CssBaseline/>
          <Router>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="apply-online" element={<ApplyStart />} />
              <Route exact path="instore" element={<Instore />} />
              <Route exact path="warehouse" element={<Warehouse />} />
              <Route exact path="office" element={<Office />} />
              <Route exact path="order" element={<Order />} />
              <Route exact path="locations" element={<Locations />} />
              <Route exact path="drinks" element={<Drinks />} />
              <Route exact path="about" element={<About />} />
              <Route exact path="contact" element={<Contact />} />
              <Route exact path="catering" element={<Catering />} />
              <Route exact path='webmail' Component={() => {
                  window.location.href = 'https://gator4026.hostgator.com:2096/';
                  return null;
              }}/>
            </Routes>
            <Footer />
          </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
