import { 
    Card,
    Box,
    CardMedia,
} from '@mui/material'
import * as React from 'react'

export default function DrinkCard(props) {

    const card = (
        <React.Fragment >
            <Card sx={{border:'solid 2px grey'}}>
                <CardMedia 
                    component="img" 
                    image={require(`../images/drinks/drink${props.image}.jpg`)} />

            </Card>
        </React.Fragment>
    )
    return (
        <Box sx={{ 
            padding:'15px',
        }}>
            <Card >{card}</Card>
        </Box>
    )
}