import { useMediaQuery, useTheme } from '@mui/material'

const useResponsiveHelper = () => {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isTablet = useMediaQuery(theme.breakpoints.only('md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'))
    const isMobileOrTablet = isMobile || isTablet
    const isTabletOrDesktop = isTablet || isDesktop
    const isVerTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

    const containerWidth = () => {
        if (isMobile) {
            return 'sm'
        }
        if (isTablet) {
            return 'md'
        }
        return 'lg'
    }

    const containerMaxWidth = () => {
        if (isMobile) {
            return '100%'
        }

        if (isTablet) {
            return '1055px'
        }
        return '1134px !important'
    }

    return { containerWidth, containerMaxWidth, isDesktop, isMobile, isTablet, isMobileOrTablet, isTabletOrDesktop, isVerTablet };

}

export default useResponsiveHelper