import { 
    Box,
    Typography, 
    Link, 
    Grid, 
    Card,
    useTheme
    } from '@mui/material'
import LocationCard from '../components/LocationCard.js'
import '../config/LocationInfo.js'
import useResponsiveHelper from '../hooks/useResponsiveHelper.js'
import { locations, locationsADDRESS, locationsMAP } from '../images'
import MetaTags from '../components/MetaTags.js'
export default function Locations() {

    const { LOCATIONS } = require('../config/LocationInfo.js')
    const { isVerTablet, isMobile, isDesktop, isTablet} = useResponsiveHelper()
    const theme = useTheme()


    const mobileMap = (
        <Box sx={{width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Box
                component='img'
                alt='menu'
                src={locations}
                sx={{                                 
                    width: '100%',
                    }}
            />                          
        </Box>
    )
    const desktopMap = (
        <Box sx={{width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Box
                component='img'
                alt='menu'
                src={locationsMAP}
                sx={{                                 
                    width: '35%',
                    }}
            />  
            <Box
                    component='img'
                    alt='menu'
                    src={locationsADDRESS}
                    sx={{                                 
                    width:'60%',
                    height:'60%'
                        }}
             />  
                
                      
        </Box>
    )
    return (
         <Box sx={{ display: 'flex', justifyContent:'center', pb:'30px'}}  >
        <MetaTags title="CoCo Edmonton Locations" description="A list of all CoCo stores in Edmonton" url={window.location.href}/>
            
         <Grid container sx={{width: isVerTablet ? '100vw' : isMobile ? '100vw' : isTablet ? '80vw' : '1800px',}}columnSpacing={1} spacing={2} justifyContent={"center"} direction={"row"} alignItem={"center"} >
         {isMobile ? mobileMap : desktopMap}
        <Grid item xs='12' sx={{}} textAlign='center'>
            <Typography variant="h6">
                *Please be advised that during the holiday season, our operating hours may differ from the regular store hours
            </Typography>
        </Grid> 
         {LOCATIONS.map((store) => {
                    return (
                        <Grid item sx={{ margin: isMobile ? '7px' : "10px", }} xs={isDesktop ? '5' : "12"} >

                        <LocationCard key={store.name} location={store}></LocationCard>
                    </Grid>
             )
             })}
             
             
         </Grid>
     </Box>
    )
}