import {logo2Image, DowntownMap, SouthMap, TamarackMap, WemMap, WindermereMap, UofAMap, NorthMap, SherwoodMap, WestgateMap, ManningMap, TempHeritageMap,
    DowntownIH, DowntownOV, SouthIH, SouthOV, TamarackIH, TamarackOV, WemIH, WemOV, WindermereIH, WindermereOV, UofAIH, UofAOV, NorthIH, NorthOV, SherwoodIH, SherwoodOV, WestgateIH, WestgateOV, ManningOV, HeritageIH, HeritageOV } from '../images'
export const LOCATIONS = [
    {
        name:'Downtown',
        number: '(780)756-7752',
        hours: ['Monday — 12:00pm-9:00pm', 'Tuesday — 12:00pm-9:00pm', 'Wednesday — 12:00pm-9:00pm', 'Thursday — 12:00pm-9:00pm', 'Friday — 12:00pm-9:00pm', 'Saturday — 12:00pm-9:00pm', 'Sunday — 12:00pm-9:00pm'],
        location: '10147 109 St NW',
        map: 'CoCo%20Downtown%20Edmonton',
        mapImg: DowntownMap,
        image: [DowntownIH, DowntownOV],
        latitude: 53.542297749185174, 
        longitude: -113.50809261912664,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "c28d1064-9b8a-4fe6-b86c-5bb46eb2fdff",
        info: "Located accross from Save-on-Foods and Popeyes, beside Greta bar",
    },
    {
        name:'Calgary Trail',
        number: '(780)757-2626',
        hours: ['Monday — 11:30pm-10:00pm', 'Tuesday — 11:30pm-10:00pm', 'Wednesday — 11:30pm-10:00pm', 'Thursday — 11:30pm-10:00pm', 'Friday — 11:30pm-11:00pm', 'Saturday — 11:30pm-11:00pm', 'Sunday — 11:30pm-10:00pm'],
        location: '#110 2920 Calgary Trail NW',
        map: 'CoCo%20South%20Edmonton',
        mapImg: SouthMap,
        image: [SouthIH, SouthOV],
        latitude: 53.45975437221164, 
        longitude: -113.4938633614615,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "c4e233bb-ce75-4a16-8217-36791c77449a",
        info: "Located beside Menjiro Ramen and Cora on Calgary Trail",
    },
    {
        name:'North 97 ST',
        number: '(780)377-1068',
        hours: ['Monday — 12:00pm-10:00pm', 'Tuesday — 12:00pm-10:00pm', 'Wednesday — 12:00pm-10:00pm', 'Thursday — 12:00pm-10:00pm', 'Friday — 12:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-10:00pm'],
        location: '12858 97 St NW',
        map: 'CoCo%2012858%2097%20Street%20Edmonton',
        mapImg: NorthMap,
        image: [NorthIH, NorthOV],
        latitude: 53.58721918386347, 
        longitude: -113.49265782878516,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "a2a88e1d-a8d8-4001-9914-012217f28d8e",
        info: "Located beside the barbershop and Tax Pros, across from print zone",
    },
    {
        name:'Tamarack',
        number: '(780)760-2626',        
        hours: ['Monday — 12:00pm-10:00pm', 'Tuesday — 12:00pm-10:00pm', 'Wednesday — 12:00pm-10:00pm', 'Thursday — 12:00pm-10:00pm', 'Friday — 12:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-10:00pm'],
        location: '885 Tamarack Way NW',
        map: 'CoCo%20Tamarack%20Edmonton',
        mapImg: TamarackMap,
        image: [TamarackIH, TamarackOV],
        latitude: 53.48011559929111, 
        longitude: -113.36756514494233,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "fb2a0e68-551b-4b5c-9e7c-6d2ae8b03880",
        info: "Beside the movie theatre and in the same parking lot as Wendy's and Jolly Bee's",
    },
    {
        name:'Windemere',
        number: '(780)430-8666',
        hours: ['Monday — 12:00pm-10:00pm', 'Tuesday — 12:00pm-10:00pm', 'Wednesday — 12:00pm-10:00pm', 'Thursday — 12:00pm-10:00pm', 'Friday — 12:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-10:00pm'],
        location: '1307 Windemere Way SW',
        map: 'CoCo%20tea%20Windermere%20way%20t6w%200p1',
        mapImg: WindermereMap,
        image: [WindermereIH, WindermereOV],
        latitude: 53.435212991301064, 
        longitude: -113.61561954903759,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: '0318b884-1607-46dd-8bff-a89f97aa7ff3', 
        info: "Located beside Tisto Cakes and Plant Life",
    },
    {
        name:'Sherwood Park',
        number: '(780)570-0046',
        hours: ['Monday — 11:00pm-9:00pm', 'Tuesday — 11:00pm-9:00pm', 'Wednesday — 11:00pm-9:00pm', 'Thursday — 11:00pm-9:00pm', 'Friday — 11:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-9:00pm'],
        location: '7000 Emerald Dr #130',
        map: 'sherwood%20park%20%20coco%20tea',
        mapImg: SherwoodMap,
        image: [SherwoodIH, SherwoodOV],
        latitude: 53.56767065159189,
        longitude: -113.28274338821824,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "de9f2632-5604-4dbf-9507-f61bf4c80671", 
        info: "Located beside the Freshi and Blaze pizza, across  the parking lot from Wine and Beyond",
    },
    {
        name:'University of Alberta',
        number: '(780)244-0257',
        hours: ['Monday — 12:00pm-9:00pm', 'Tuesday — 12:00pm-9:00pm', 'Wednesday — 12:00pm-9:00pm', 'Thursday — 12:00pm-9:00pm', 'Friday — 12:00pm-9:00pm', 'Saturday — 12:00pm-9:00pm', 'Sunday — 12:00pm-9:00pm'],
        location: '8407 112 St NW',
        map: 'CoCo%20university%208407%20112%20street%20Edmonton',
        mapImg: UofAMap,
        image: [UofAIH, UofAOV],
        latitude: 53.522466257624686, 
        longitude: -113.51930479665836,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: '6292a26f-cc59-4543-9891-a46a8db9d953', 
        info: "Located beside the main University of Alberta campus and Tim Horton's",
    },
    {
        name:'West Edmonton Mall',
        number: '(780)244-7043',
        hours: ['Monday — 10:00am-9:00pm', 'Tuesday — 10:00am-9:00pm', 'Wednesday — 10:00am-9:00pm', 'Thursday — 10:00am-9:00pm', 'Friday — 10:00am-10:00pm', 'Saturday — 10:00am-10:00pm', 'Sunday — 11:00am-7:00pm'],
        location: '#2371 8882 170 St NW',
        map: 'CoCo%20West%20Edmonton%20Mall',
        mapImg: WemMap,
        image: [WemIH, WemOV],
        latitude: 53.52249316222125, 
        longitude: -113.62713396576241,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "e3d2fdc0-ba6d-4895-ba18-074e2ffe9506",
        info: "Located inside West Edmonton Mall, beside Bubba Gump and across from Simons", 
    },
    {
        name:'Westgate',
        number: '(780)249-2626',
        hours: ['Monday — 12:00pm-10:00pm', 'Tuesday — 12:00pm-10:00pm', 'Wednesday — 12:00pm-10:00pm', 'Thursday — 12:00pm-10:00pm', 'Friday — 12:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-10:00pm'],
        location: '17010 90 Ave NW Unit 118',
        map: 'westgate%20coco%20tea%20edmonton',
        mapImg: WestgateMap,
        image: [WestgateIH, WestgateOV],
        latitude: 53.52692042061041, 
        longitude: -113.61737676415017,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "fac54a16-4321-4f04-8fe8-beed24ee6ab8", 
        info: "Located beside West Edmonton Mall, in the same parking lot as McDonald's and Westgate Medical Center",
    },
    {
        name:'Manning',
        number: '(780)752-3320',
        hours: ['Monday — 12:00pm-10:00pm', 'Tuesday — 12:00pm-10:00pm', 'Wednesday — 12:00pm-10:00pm', 'Thursday — 12:00pm-10:00pm', 'Friday — 12:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-10:00pm'],
        location: '15542 37 St NW',
        map: 'CoCo%20Fresh%20Tea%20Juice%20Manning%20Town%20Centre%2015542%2037%20st',
        mapImg: ManningMap,
        image: [WestgateIH, ManningOV],
        latitude: 53.48465776907156, 
        longitude: -113.36757541299855,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "18be0dfa-46a7-4113-bbc0-612b124faaf2", 
        info: "Located in Manning Town Centre, beside Wing Snob and Canadian Brewhouse, close to the Cinplex Cinemas, facing SportChek",
    },
    {
        name:'Heritage Valley',
        number: '(780)784-9968',
        hours: ['Monday — 12:00pm-10:00pm', 'Tuesday — 12:00pm-10:00pm', 'Wednesday — 12:00pm-10:00pm', 'Thursday — 12:00pm-10:00pm', 'Friday — 12:00pm-10:00pm', 'Saturday — 12:00pm-10:00pm', 'Sunday — 12:00pm-10:00pm'],
        location: '2840 James Mowatt Trail SW',
        map: '2840%20James%20Mowatt%20Trail%20SW',
        mapImg: TempHeritageMap,
        image: [HeritageIH, HeritageOV],
        latitude: 53.406463, 
        longitude: -113.538241,
        cuid: "b1eaf06e-63bf-45bc-a76d-e92c114776dc",
        ruid: "94063242-2289-45aa-b1db-67cd9ffd18fb", 
        info: "Located in Heritage Valley, beside Popeyes, close to the Real Canadian Superstore, facing Dominion's Pizza",
    },

]

export default LOCATIONS