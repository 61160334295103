import { useTheme, useMediaQuery, IconButton, Link, Typography, Box, Button } from '@mui/material'
import { useState, useEffect } from 'react'
import { Slant as Hamburger } from 'hamburger-react'
import { ClickAwayListener } from '@mui/base';
import useResponsiveHelper from '../hooks/useResponsiveHelper';

export default function MobileMenu(props) {

    const isHomepage = window.location.pathname === '/'
    const { menuList, bg_color } = props 
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const [offset, setOffset] = useState(0)
    const largePhone = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const { isMobile, } = useResponsiveHelper()

    function simulateMouseClick() {
        var element = document.querySelector('div[class="hamburger-react"]');
        element.click()
        
    }
    useEffect(() => {
        
        const onScroll = () => {
            setOffset(window.scrollY)
            if (open && !isMobile) {
                
                simulateMouseClick()
                setOpen(false)
            }
        }
        
        window.removeEventListener("scroll", onScroll)
        window.addEventListener("scroll", onScroll, { passive: true })

        return () => window.removeEventListener("scroll", onScroll)
    }, [open]);

    
    const CustomLink = (name, path) => {
        return (
            <Link 
                href={path} 
                color="secondary" 
                variant="menu" 
                sx={{
                    padding:"20px",
                    width:"100%",
                    display:"flex",
                    justifyContent: "center",
                    '&:hover': {
                        backgroundColor: 'rgba(100,100,100,0.1)',
                        borderRadius: 5,
                    },
                    '&:focus': {
                        backgroundColor: 'rgba(100,100,100,0.3)'
                    }
                }}
                underline='none'>
                {name}
            </Link>
            
        )
    }

    return (
        <ClickAwayListener onClickAway={() => open ? simulateMouseClick() : null}>
            <Box>
                <Box sx={{
                    top:0,
                    right:'55px',
                    color: theme.palette.secondary.main,
                   
                }}>
                <Hamburger rounded direction={'left'}
                // toggled={open}
                // onToggle={(toggled) => {
                //     if (toggled && !open) {
                //         setY(window.scrollY);
                //         setOpen(true);
                //     } else {
                //         setOpen(false);
                //     }
                    
                // }} 
                toggled={open}
                toggle={setOpen}
                size={42}
                />
                </Box>
                {open ? 
                    <Box id='menubox' sx={{
                        position: 'absolute',
                        top: !largePhone ? '130px' : '158px',
                        width: '100%',
                        left:0,
                        backgroundColor: bg_color,
                        display:'flex',
                        flexDirection:'column',
                        alignItems: 'center',
                        zIndex:2,
                        borderBottom: '2px solid #59666Eff',
                        borderRadius: isMobile ? '30px' : 0,

                }}>
                    {menuList ? menuList.map((item) => {
                        return (
                            <div>
                                <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.secondary.main}}/>
                                {CustomLink(item, `/${item.toLowerCase()}`)}
                            </div>
                        )
                    }) :  
                    <Box>
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.secondary.main}}/>
                    {CustomLink("Order", "/order")}
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.primary.main}}/>
                    {CustomLink("Locations", "locations")}
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.primary.main}}/>
                    {CustomLink("Drinks", "/drinks")}
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.primary.main}}/>
                    {CustomLink("Apply", "/apply-online")}
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.primary.main}}/>
                    {CustomLink("About", "/About")}
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.primary.main}}/>
                    {CustomLink("Contact", "/contact")}
                    <hr style={{width:'100%', border:"1px solid", borderColor: theme.palette.primary.main}}/>
                    {CustomLink("Catering", "/catering")}
                    </Box>
                    }
                       
                    </Box> 
                : null }
            </Box>
        </ClickAwayListener>


    )

}