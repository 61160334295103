import {React, useRef} from 'react';
import { Container, Typography, TextField, Button, Box, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import emailjs from '@emailjs/browser';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import MetaTags from '../components/MetaTags';
export default function Catering() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_6jy75ge', 'template_dq2nnfv', form.current, 'JxdUi7ueAgPAqoMYR')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        alert("Your catering request have been sent! Please wait for a response.")
        window.location.href = '/';
    };
    

    return (
    <Container maxWidth="sm">
            <MetaTags title="CoCo Edmonton Catering" description="Details on CoCo catering service" url={window.location.href}/>

            <Typography variant="h4" gutterBottom>Catering Inquiries</Typography>
            <form ref={form} onSubmit={sendEmail}>
                <TextField
                    id="name"
                    label="Name"
                    name="name"
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle  />
                          </InputAdornment>
                        ),
                      }}
                />

                <TextField
                    id="phone"
                    label="Phone Number"
                    name="phoneNumber"
                    helperText="eg. 123-456-7890"
                    fullWidth
                    required
                    type="tel"
                    placeholder="123-456-7890"
                    inputProps={{
                        maxLength: 12, // adjust as needed based on your formatting
                        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}", // only allow numeric input
                      }}
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

                <TextField
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

                <TextField
                    id="location"
                    label="Event Location"
                    name="location"
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

                <TextField
                    id="date"
                    label="Event Date"
                    name="date"
                    type="date"
                    fullWidth
                    required
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EventIcon  />
                          </InputAdornment>
                        ),
                      }}
                />

                <FormControl size="large" sx={{ margin: "10px", width: '100%'}}>
                    <InputLabel id="amount"># of Drinks</InputLabel>
                    
                    <Select
                        required
                        labelId="amount"
                        name="amount"
                        id="amount"
                        label="# of Drinks"
                        sx={{
                            height: "50px",
                            '& .MuiSelect-select': {
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            },
                        }}
                        startAdornment= {
                          <InputAdornment position="start">
                            <LocalDrinkIcon/>
                          </InputAdornment>
                        }
                      >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="30-49">30 - 49</MenuItem>
                    <MenuItem value="50-79">50 -79</MenuItem>
                    <MenuItem value="80-149">80 - 149</MenuItem>
                    <MenuItem value="150-249">150 - 249</MenuItem>
                    <MenuItem value="250+">250 +</MenuItem>
                    </Select>
                </FormControl>
                
                <TextField
                    id="message"
                    label="Additional Request (optional)"
                    name="message"
                    multiline
                    rows={3}
                    fullWidth
                    sx={{
                        margin: "10px",
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MessageIcon  />
                          </InputAdornment>
                        ),
                      }}
                />
                
                {/* Submit Button */}
                <Box display={"flex"} justifyContent={"center"} margin="10px">
                    <Button type="submit" variant="contained" color="primary" size="large">
                        Submit
                    </Button>
                </Box>
            </form>
      </Container>
    )
}